.mainContainer {
  width: 320px;
}

.listContainer {
  width: 100%;
}

.cardHeader {
  display: grid;
  align-items: center;
  justify-content: space-between;
  grid-template-columns: 3fr 1fr;
  padding: 12px;
}

.notificationLink {
}

.notificationContainer {
  cursor: pointer;
}

.notificationContainer:hover {
  background-color: rgb(231, 231, 231);
}

.notificationFooter {
  padding: 4px 8px;
  text-align: right;
}

.notificationMakeReaded {
  text-align: center;
}

.notificationBodyMessage {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: 50px auto;
  padding: 12px;
}

.notificationBodySubs {
  align-items: center;
  padding: 12px;
  width: 100%;
}

.noNotificationBody {
  padding: 18px;
  text-align: center;
}

.notificationFromName {
  font-weight: bold;
}

.mainContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  padding: 0 10px;
  min-height: 250px;
  align-items: center;
  justify-content: center;
  border: 2px solid #aaaaaa;
  border-top: 0px;
}