.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0;
  border-width: 2px;
  border-radius: 2px;
  border-color: #64ceff;
  border-style: solid;
  background-color: #fafafa;
  color: #5a5a5a;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.ul {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.li {
  position: relative;
  float: left;
}

.image {
  display: block;
  text-align: center;
  text-decoration: none;
  margin: 3px;
  width: 8em;
}

.remove {
  position: absolute;
  top: 0;
  right: 0;
  color: #64ceff;
  border-radius: 0 0 0 2px;
  padding: 5px;
  cursor: pointer;
  outline: none;
}

.uploadLabelIcon {
  align-items: center;
  display: flex;
  color: #5a5a5a;
  font-weight: 600;
}
