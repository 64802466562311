body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.animated-loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.animated-loader div {
  position: absolute;
  top: 33px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.animated-loader div:nth-child(1) {
  left: 8px;
  animation: animated-loader1 0.6s infinite;
}
.animated-loader div:nth-child(2) {
  left: 8px;
  animation: animated-loader2 0.6s infinite;
}
.animated-loader div:nth-child(3) {
  left: 32px;
  animation: animated-loader2 0.6s infinite;
}
.animated-loader div:nth-child(4) {
  left: 56px;
  animation: animated-loader3 0.6s infinite;
}
@keyframes animated-loader1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes animated-loader3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes animated-loader2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
