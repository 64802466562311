.container {
  width: 100%;
  display: flex;
  height: 70px;
  align-items: center;
  cursor: pointer;
}

.container_readed {
  background-color: #c8efff;
}

.container:hover {
  background-color: #c8efff;
}

.avatar {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #64ceff;
}

.content {
  width: calc(100% - 70px);
  padding: 8px;
  padding-right: 0;
}

.time {
  font-style: italic;
  color: rgb(145, 145, 145);
  display: flex;
  justify-content: space-between;
}

.author {
  font-weight: 600;
}

.hideButton {
  font-weight: 600;
  font-style: italic;
  text-decoration: underline;
  color: #64ceff;
  z-index: 9;
}
