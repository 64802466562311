.dateTimeContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
}

.timePartContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.calendarPartContainer {
  border-right: 2px solid #2681cc;
}

.buttonsContainer {
  justify-content: flex-end;
  text-align: right;
  margin-right: 10px;
}

@media only screen and (max-width: 600px) {
  .dateTimeContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content;
    row-gap: 10px;
  }
  .calendarPartContainer {
    border-right: none;
    margin: auto;
    text-align: center;
    width: 325px;
  }

  .timePartContainer {
    justify-content: normal;
    text-align: center;
  }

  .buttonsContainer {
    margin-top: 24px;
    margin-right: 0;
    justify-content: normal;
    text-align: center;
  }
}

.timeContainer {
  width: 100%;
}

.timeInput {
  background-color: #ffffff;
  border: 6px solid #2681cc;
  border-radius: 30px;
  height: 90px;
  width: 130px;
  line-height: 90px;
  color: black;
  padding: 0 5px 0 15px;
  user-select: none;
  font: 500 50px 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
}

.timeInput:focus {
  outline: none;
  background-color: #2681cc;
  color: #ffffff;
}

.timeInput::-webkit-outer-spin-button,
.timeInput::-webkit-inner-spin-button {
  padding-left: 15px;
}

.timeDivider {
  color: black;
  font-weight: 700;
  line-height: 100px;
  height: 100px;
  font-size: 60px;
  margin: 0px 10px;
  user-select: none;
}
