.menu_ul {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 2;
}

.menu_link {
  color: white;
  text-decoration: none;
  cursor: pointer;
}

.sub_menu_item {
  white-space: nowrap;
  color: white;
  text-decoration: none;
  font-size: 15px;
  font-weight: 600;
}

.copyright {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.socialIcon {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  user-select: none;
}
