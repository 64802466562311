.adminContainer {
  display: grid;
  grid-template-columns: 220px auto;
  width: 100%;
  min-height: 65vh;
}

.sidebarContainer {
  overflow: auto;
}

.adminContent {
  width: 100%;
  height: 100%;
}
