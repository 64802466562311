.container {
    position: relative;
    flex-grow: 1;
    background-color: #ffffff;
    border-radius: 7px;
  }
  
  .menuItem {
    padding: 1em;
  }
  
  .paper {
    position: absolute;
    z-index: 99999;
    margin-top: 4px;
    left: 0;
    right: 0;
  }

  .chip {
    margin: 4px 2px;
  }